import logo from "./logo.png";

const clientData = {
    client_entity: 54,
    attorney_firm: 'Adam Thrash',
    attorney_name: 'Adam Thrash',
    attorney_number: '601-948-7410',
    attorney_email: 'athrash@thrashandthrash.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc-_thrash_and_thrash+(720p).mp4',
    mainColor: '#242424',
    secondaryColor: '#00258d',
    siteLink: 'http://www.thrashandthrash.com/',
    logo
}

export default clientData